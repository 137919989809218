#header{
	position: relative;
	background: @primary-bg;
	padding: 15px;

	.inner{
		position: relative;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.nav-container{
		display: none;
		.special {
				display: block;
				a{
					.button;
					padding: 4px 6px;
					height: 36px;
					line-height: 36px;
					min-height: 36px;
					width: unset;
					border:0;
					max-width: 200px;
				}
		}

		&.active{
			display: block;
			width: 100%;
			padding: 10px 0;

			li{
				padding: 2px 0;
				font-size: 1.8rem;
				text-align: center;
			}

			a{
				color: #fff;
			}
		}
	}

	#grip{
		height: 48px;
		width: 52px;
		margin: 0;
		span{
			display: none;
		}
	}

	.logo-container{
		position: relative;
		height: 48px;
		line-height: 48px;
		max-width: 70%;
		text-transform: uppercase;
		font-size: 2rem;
		font-weight: bold;

		img{
			height: 100%;
		}
	}

	.special-container {
		display: none;
	}

.desktop({

	.inner{
		// height: 96px;
	}
	.logo-container{
		position: relative;
		display: block;
		max-width: 278px;
		// line-height: 96px;
		// height: 96px;
		height: auto;

		a{
			// line-height: 96px;
			// height: 96px;

			&:hover{
				opacity: .7;
			}
		}

		img{
			display: block;
			max-height: 145px;
		}
	}

	.nav-container{
		display: block;
		margin: auto;
		height: 36px;
		padding: 24px 0;
		width: unset;

		ul{
			display: block;
			height: 36px;
		}

		li{
			float: left;
			padding: 0 5px;

			&.special{
				display: none;
				// position: absolute;
				// right:0;

			}
		}

		a{
			display: inline-block;
			height: 36px;
			line-height: 36px;
			color: #fff;
			padding: 0 5px;
			&:hover{
				color: @button-text;
				background-color: @button-bg;
			}
		}

	}
	.special-container {
		display: flex;
		align-items: center;
		gap: 8px;
		.special {
			display: inline-block;
			height: 36px;
			margin-bottom: 0;
			a{
				.button;
				padding: 4px 6px;
				height: 36px;
				line-height: 36px;
				min-height: 36px;
				width: unset;
				border:0;
			}
		}
	}

	#grip{
		display: none;
	}
});


}