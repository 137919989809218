.block.hero{
	position: relative;

	img{
		display: block;
		width: 100%;
	}

	.hero-content{
		padding: 35px 15px;
	}

	h1,h2,h3{
		font-size: 3rem;
		line-height: 1;
		padding-bottom: 15px;
	}

	p{
		padding-bottom: 1em;
	}

	a{
		.button;
		.ghost;
		.inline;
		width: auto;
		min-height: auto;

		&:hover{
			background: none;
			color: @text;
		}
	}

	.desktop({
		overflow: hidden;
		.background-image{
			position: absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			z-index: 100;

			img{
				display: block;
				min-height: 100%;
				min-width: 100%;
			}
		}
		.hero-content{
			width: 40%;
			margin-left: 0;
			position: relative;
			box-sizing: border-box;
			padding: 145px 45px 200px 10px;
			color: #fff;
			z-index: 200;

			* {
				position: relative;
			}

			&:before{
				position: absolute;
				top:0;
				left:-9000px;
				right:0;
				bottom:0;
				content: "";
				background: @primary-bg;
				opacity: .7;
			}
		}

		a{
			color: #fff;
			padding-right: 5px;
			padding-left: 5px;

			&:hover{
				background: @button-bg;
				color: #fff;
			}
		}
	});
}