.block.image-feature{
	position: relative;
	padding: 15px;

	img{
		display: block;
		width: 100%;
	}

	article{
		padding: 15px 0 0 15px;
		border-left: 5px solid @button-border;
	}

	h1,h2,h3,h4,h5{
		font-size: 3rem;
		padding-bottom: 1rem;
	}

	p{
		padding-bottom: 1rem;
	}

	a{
		.button;
		margin-left: 0;
		max-width: 100%;
		display: inline-block;
		width: auto;
		padding: 12px;
		min-height: 0;
	}


.desktop({
	.inner{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		figure{
			width: 48%;
		}

		article{
			width: 48%;
			box-sizing: border-box;
			padding: 0 0 0 30px;
			height: 100%;
		}
	}

	+ .image-feature{
		figure{
			order: 1;
		}

		article{
			order:0;
		}
	}

	&.alt{
		figure{
			order: 1;
		}

		article{
			order:0;
		}
	}
	&.alt + .image-feature{
		figure{
			order: 0;
		}

		article{
			order:1;
		}
	}
});
}