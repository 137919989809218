.block.welcome{
	position: relative;
	padding: 0 15px 35px;
	z-index: 300;

	.inner{
		position: relative;
		background:  #fff url(/assets/img/stone.jpg) center;
	}

	article{
		padding: 15px;
		box-shadow: 0 0 15px rgba(0,0,0,.3);
		text-align: center;
	}

	.socials {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		padding-top: 12px;
		gap: 10px;
		width: 100%;
		.social {
			height: 25px;
			width: 25px;
			a {
				border: none !important;
				padding: 0;
				svg {
					fill: @button-bg;
					transition: all .3s ease;
					height: 100%;
					width: 100%;
				}
				&:hover, &:focus {
					background-color: transparent;
					svg {
						opacity: 0.6;
					}
				}
			}
			&:has(a[href=""]) {
				display: none;
			}
		}
	}

	a{
		border-bottom: 4px solid @button-bg;

		&:hover{
			background: @button-bg;
			color: @button-text-hover;
		}
	}

	.desktop({
		position: relative;
		article{
			padding: 45px;
			margin: -60px auto 0;
			box-sizing: border-box;
			min-height: 100px;
			font-size: 1.9rem;
		}
	});
}