@import '../base/_utilities';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap');

// universal
* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&:focus {
		outline: @button-bg auto 3px;
	}
}

img{
	max-width: 100%;
}


// links
a {
	text-decoration: none;
	transition: ease all .3s;
	outline: none;
	color: @text-link;

	&:hover, &:focus {
		text-decoration: none;
	}

	&:active {
		text-decoration: none;
	}

	/* mobile tap color */
	&:link {
		-webkit-tap-highlight-color : rgba( 0, 0, 0, 0.3 );
	}
}


// headings
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
	text-rendering: optimizelegibility;
	margin: 0;
	font-weight: 300;
	line-height: 1.3;
	font-family: @heading;
}

h1, .h1 {
	.fontSize(3.9,3.9,3.9);
}

h2, .h2 {
	.fontSize(3.9,3.9,3.9);
	padding-bottom: 15px;
}

.button, a.button{
	position: relative;
	display: block;
	line-height: 24px;
	padding: 12px 0;
	min-height: 48px;
	background: @button-bg;
	border: 2px solid @button-border;
	color: @button-text;
	font-size: 1.5rem;
	font-weight: bold;
	width: 100%;
	max-width: 300px;
	border-radius: 1px;
	transition: ease all .3s;
	text-align: center;
	margin: auto;

	&.ghost{
		background: none;
		color: @button-bg;
		border:0;
		border-bottom: 3px solid @button-border;
	}

	&.inline{
		display: inline-block;
	}

	&:hover{
		background: @button-bg-hover;
	}
}

.outline{
	background: none;
	border: 2px solid blue;
	color: #000;
}

p {
	margin: 0;
}

ul, ol {
	margin: 0;
	padding:0;
}

p, ul, ol, dl {
	// + p, + ul, + ol, + dl {
	// 	margin-top: 1.5em;
	// }
}

nav, .nav, nav ul, .nav ul {
	list-style-type: none;
	padding-left: 0;
}

hr {
	border:none;
	background:#eee;
	height:2px;
	width:60px;
	margin:35px auto 0;
}

.alignleft, .alignright {
	margin-bottom: 1em;
}

address {
	font-style: normal;
}

.center {
	text-align: center;
}

.upper {
	text-transform: uppercase;
}

.screen-reader-text {
	position: absolute !important;
	left: -999em;
}

.html-times {
	font-size: 1.5em;
	vertical-align: sub;
}

.generic-text{
	padding: 50px 20px;
	p, ul, ol, dl{
		padding-bottom: 20px;
	}
}