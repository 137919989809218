.block.inline-links{
	position: relative;
	text-align: center;
	padding: 15px;

	.inner{
		padding: 15px;
		border-top: 2px solid @primary-bg;
		border-bottom: 2px solid @button-border;
	}

	.option{
		display: inline-block;
		background-color: transparent;
		font-size: 2.5rem;
		line-height: 1.5;
		margin: 0 25px;
		border: none;
		border-bottom: 2px solid #fff;
		cursor: pointer;
		transition: ease all 0.3s;
		&:hover, &.selected{
			border-bottom: 2px solid @button-border;
		}
	}

	strong{
		display: block;
		font-size: 1.6rem;
	}

.desktop({
	.option{
		font-size: 4.5rem;
	}
});
}