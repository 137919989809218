.block.image-columns{
	position: relative;
	padding: 15px;

	.inner{
		position: relative;
		overflow: hidden;
		display: flex;
		justify-content: end;
		flex-direction: column;
	}

	figure{
		position: relative;
		height: 100%;
		width: 100%;
		margin-bottom: 15px;
		img {
			object-fit: cover;
			height: 230px;
			width: 100%;
		}
	}

	article{
		width: 100%;
		background:  #fff url(/assets/img/stone.jpg) center;
	}

	h1,h2,h3,h4,h5{
		background: @primary-bg;
		padding: 8px;
		text-align: center;
		color: #fff;
		margin-bottom: 10px;
	}

	ul{
		padding-bottom: 20px;
	}

	li{
		margin-left: 32px;
		list-style: outside square;
		padding-bottom: 10px;
	}


.tablet({
	padding: 15px 0;
	.inner{
		position: relative;
		display: flex;
		flex-direction: row;
	}

	figure{
		float: none;
		width: 30%;
		margin-left:0;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		margin-bottom: 0;
		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	article{
		width: 67%;
		margin:0;
		padding:0;
		display: flex;
		flex-direction: column;
		padding-right: 15px;
		justify-content: space-between;
	}

	h1,h2,h3,h4,h5{
		padding: 16px;
	}
});

.desktop({
	article{
		flex-direction: row;
	}
	.column{
		width: 47%;
	}
});
}