.block.contact{
	position: relative;
	padding: 15px 15px 0;
	margin-bottom: 40px;

	.inner{
		position: relative;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: end;
	}

	figure{
		position: relative;
		height: 100%;
		width: 100%;
		margin-bottom: 15px;
		img {
			object-fit: cover;
			height: 230px;
			width: 100%;
		}
	}

	article{
		width: 100%;
	}

	.column{
		padding-bottom: 35px;
		a {
			display: block;
			color: @text;
			display: flex;
			align-items: center;
			&:hover, &:focus {
				color: @text-link;
			}
		}
		a[href="tel:+1"],
		a[href="mailto:"],
		a[href=""]:not(.address) {
				display: none;
		}
	}

	.iwrap{
		padding-bottom: 20px;
	}

.tablet({
	padding: 15px 15px 0 0;
	.inner{
		position: relative;
		overflow: hidden;
		display: flex;
		flex-direction: row;
		justify-content: end;
	}

	header{
		padding-top: 40px;

		h2 span, .title span{
			position: relative;
			top: -8px;
			display: inline-block;
			height: 3px;
			width: 220px;
			background: var(--button-border-color);
		}

		.title {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			gap: 1ch;
		}
	}

	figure{
		float: none;
		width: 30%;
		margin-left:0;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		margin-bottom: 0;
		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	article{
		width: calc(70% - 30px);
		margin:0;
		padding: 20px 0;
	}

	.column{
		width: 50%;
		float: left;
		padding-top: 40px;
	}

	.form{
		width: 100%;
		clear: both;
		display: block;
		flex: none;
		padding: 45px 0;
	}

	h1,h2,h3,h4,h5{
		font-size: 3.5rem;
		padding-bottom: 15px;
	}

});

.desktop({
	figure {
		width: 48%;
	}
	article{
		flex-direction: row;
		width: calc(50% - 30px);
	}
	.column{
		width: 47%;
	}
});
}

#rs-builder {
	.block.contact {
		.column {
			a[href="tel:+1"],
			a[href="mailto:"] {
				display: block;
			}
		}
	}
}