.block.gallery{
	position: relative;
	padding: 35px 15px;

	.inner + .inner{
		max-width: 100%;
	}

	h1,h2,h3,h4,h5,h6{
		font-size: 3rem;
		padding-bottom: 0;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		column-gap: 0.5ch;
		&::after {
			content: '';
			display: inline-block;
			height: 3px;
			width: 220px;
			background: var(--button-border-color);
			position: relative;
		}
	}

	ul{
		padding-top: 25px;
		display: flex;
		flex-wrap: wrap;
		// justify-content: space-between;
	}

	li{
		list-style: none;
		width: 48%;
		padding-bottom: 3vw;
	}


.desktop({
	ul{
		margin: 0 -2px;
	}
	li{
		border: 2px solid #fff;
		width: 25%;
		overflow: hidden;
		padding: 0;
		margin: 0;
		box-sizing: border-box;
	}

	a{
		overflow: hidden;
		position: relative;
		img{
			position: relative;
			transition: ease all .3s;
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}

	a:hover{
		img{
			transform: scale(1.1);
		}
	}
});
}