.block.text-break{
	position: relative;
	padding: 35px 15px;

	h1,h2,h3,h4,h5,h6{
		font-size: 3rem;
		padding-bottom: 0;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		column-gap: 0.5ch;
		&::after {
			content: '';
			display: inline-block;
			height: 3px;
			width: 220px;
			background: var(--button-border-color);
			position: relative;
		}
	}

	p{
		padding-bottom: 1.5em;
	}
}