html {
	font-size: 62.5%;
}

body{
	font-size: 1.6rem;
	line-height: 1.75;
	color: @text;
	font-family: @main;
	background: #fff url(/assets/img/country-quilt2.jpg) center;
}

img{
	display: block;
}

#mainwrap{
	overflow: hidden;
}

.inner{
	position: relative;
	margin: auto;
	max-width: @maxwidth;
	width: 100%;
}

.grecaptcha-badge {
	z-index: 1;
}

.animated-element {
	opacity: 0;
}