.block.notice{
	background: @secondary-bg;
	color: #fff;
	padding: 15px;
	font-size: 1.3rem;
	text-align: center;

	.inner {
		display: flex;
    justify-content: center;
    gap: 1.5ch;
	}

	address, .phone {
		display: inline-block;
	}

	p {
		display: inline-flex;
	}

	a {
		color: #fff;
		display: inline-flex;
		align-items: center;
		svg {
			height: 1em;
			width: 1em;
			margin-right: 5px;
			* {
				fill: #fff;
				margin-right: 5px;
				transition: fill 0.3s ease;
			}
		}
		&:hover{
			color: @text-link;
			svg {
				* {
					fill: @text-link;
				}
			}
		}
	}

	strong{
		font-weight: bold;
		display: inline-block;
	}
}