.block.footer{
	position: relative;
	padding: 15px;
	background: @secondary-bg;
	color: #fff;

	a{
		color: @button-bg;

		&:hover{
			color: #fff;
		}
	}


.desktop({
	article{
		position: relative;
		display: flex;
		justify-content: space-between;
	}
})
}