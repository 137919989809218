.block.floorplan-preview{
	position: relative;
	padding: 35px 15px;

	article{
		position: relative;
		background: @secondary-bg;
		color: #fff;
		border: 5px solid @button-border;
		text-align: center;
		padding: 25px;
	}

	li{
		list-style: none;
	}


.desktop({
	.inner{
		display: flex;
		border: 5px solid @button-border;
	}

	article{
		border:0;
		width: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	figure{
		width: 50%;
		order:1;
	}
});
}